<template>
  <div class="footer_nav">
    <img
      class="logo"
      referrerpolicy="no-referrer"
      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf60078a1831327a4683c376bee7b5d2be0558ae8431601672b8a9f3ebc196d41"
    />
    <div class="menu">
      <span class="label">互为产品</span>
      <div class="content">
        <span class="item" @click="go('/greenEnergy')">绿能派</span>
        <span class="item" @click="go('/greenEnergyExpert')">绿能专家</span>
      </div>
    </div>
    <div class="menu">
      <span class="label">企业介绍</span>
      <span class="item" @click="go('/about')">关于我们</span>
    </div>
    <div class="menu">
      <span class="label">合作伙伴</span>
      <div class="partner_box">
        <div class="img" v-for="item in state.partnerList" :key="item.name">
          <img :src="require('../assets/img/partner/'+ item.logo)" :alt="item.name" >
        </div>
      </div>
    </div>
    <div class="menu company">
      <span class="label">杭州互为综合能源服务有限公司</span>
      <div class="contents">
        <span class="item">浙江杭州西湖区天堂软件园D幢9楼D座</span>
        <span class="item">huwei&#64;huweigreen.com</span>
        <span class="item">0571-85330337</span>
      </div>
      <div class="code_box">
        <div class="code_content">
          <img
            class="image"
            referrerpolicy="no-referrer"
            src="../assets/img/wx_account.png"
          />
          <span class="item">绿能派公众号</span>
        </div>
        <div class="code_content">
          <img
            class="image"
            referrerpolicy="no-referrer"
            src="../assets/img/mini_app.png"
          />
          <span class="item">绿能派小程序</span>
        </div>
      </div>
    </div>
  </div>
  <div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021039735号-5</a></div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive } from "vue";
export default {
  setup(){
    const router = useRouter()
    const state = reactive({
      partnerList: [
        {
          name: '万豪国际集团',
          logo: 'wh.png'
        },
        {
          name: '海外海集团',
          logo: 'hwh.png',
          href: 'http://www.haiwaihai.com/'
        },
        {
          name: '两淮控股集团',
          logo: 'lhkg.png'
        },
        {
          name: '首旅如家',
          logo: 'rj.png'
        },
        {
          name: '国家电网',
          logo: 'gjdw.png'
        },
        {
          name: '中国饭店协会',
          logo: 'cn_hotel_association.png'
        },
        {
          name: '浙江饭店业协会',
          logo: 'zjfdxh.png'
        },
        {
          name: '格雷斯精选酒店',
          logo: 'gls.png'
        },
      ]
    })
    const go = (e) => {
      router.replace(e)
    }
    return{
      state,
      go
    }
  }
};
</script>

<style lang="scss" scoped>
// $design-width: 1920;
// @function pxttrem($px) {
//   @return $px/$design-width*10+rem;
// }
.beian{
  height: 40px;
  text-align: center;
  line-height: 20px;
  a{
    color: #ccd3db;
    background: #F4F4F4;
    padding: 5px 20px;
    border-radius: 5px;
    text-align: center;
  }
  a:hover{
    color: #ffffff;
    background: #1667ff;
  }
}
.footer_nav {
  background-color: #ffffff;
  width: 100%;
  height: 658px;
  margin-bottom: 1px;
  display: flex;
  padding: 160px 160px 160px 200px;
  box-sizing: border-box;
  .logo {
    width: 60px;
    height: 66px;
    margin-right: 50px;
  }
  .menu {
    margin-left: 80px;
    display: flex;
    flex-direction: column;
    .label {
      font-size: 25px;
      color: #3D3F55;
      padding-bottom: 32px;
    }
    .content {
      display: flex;
      flex-direction: column;
    }
    .contents {
      display: flex;
      flex-direction: column;
      .item {
        padding-bottom: 8px;
      }
    }
    .item {
      font-size: 18px;
      line-height: 25px;
      color: #727272;
      padding-bottom: 16px;
      cursor: pointer;
    }
    .item:hover {
      color: #1667ff;
    }
    .partner_box {
      width: 480px;
      display: grid;
      margin-top: -25px;
      grid-template-columns: repeat(3, 1fr);
      .img {
        width: 140px;
        height: 55px;
        padding: 20px 20px 0;
        img {
          width: 140px;
          height: 55px;
        }
      }
    }
  }
  .company {
    margin-left: 138px;
  }
  .code_box {
    display: flex;
    .code_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 28px;
      .image {
        width: 133px;
        height: 133px;
      }
      .item {
        font-size: 16px;
      }
    }
  }
}

</style>