<template>
  <div class="home_box">
    <div class="home_bg">
      <div class="bg_content">
        <div class="head_title">每人每天 省一度电</div>
        <div class="content_text">
          绿能派致力于酒店节能，倡导绿色住宿，节约用电。<br/>
          为世界更美好贡献自己的一份力量！
        </div>
        <div class="green_code_nav">
          <img class="img_code" src="https://sys.huwei.xin:7443/huwei-website/wx_account.png" alt="">
          <div class="slogan_text">
            加入绿能派，<br/>
            为低碳生活出一份力！
          </div>
        </div>
      </div>
    </div>
    <!-- 绿能派 nav -->
    <div class="green_energy_nav">
      <div class="green_energy_box">
        <div class="app_explain">
          <div class="app_explain_bg">GREEN ENERGY PIE</div>
          <div class="name">绿能派</div>
          <div class="slogan">致力于公益的节能大使</div>
        </div>
        <div class="description_box">
          <div class="description_text">“绿能派”由杭州互为综合能源服务有限公司创建，始终秉承“源于社会，回馈社会”的社会责任理念，大力倡导和发展绿色低碳事业，积极发起并参与教育、环保、慈善等社会公益项目。</div>
        </div>
      </div>
      <div class="public_benefit_column">
        <div class="content_nav">
          <div class="description_text">
            <div>
              <div class="title">微公益</div>
              <div class="en_title">Micro public welfare</div>
            </div>
            <div class="public_benefit_content">
              <div class="address">安民乡中心小学</div>
              <div class="coord">丽水市松阳县安民乡大潘坑村</div>
              <div class="address_introduce">
                安民乡中心小学位于松阳县西南山区，
                交通不便，气候高寒。当地主要以农业收入为主，收入单一，
                学生家庭大多条件艰苦，父母双方外出打工的家庭很多。<br/><br/>
                据校长陈梦倩介绍，全校有六个年级一个幼儿班共46名学生，
                13名教师，由于交通不便，学生和老师都是一周只回家一次。
              </div>
            </div>
          </div>
          <div class="swipper_box">
            <el-carousel :autoplay="false" @change="change" ref="carusel" arrow="never" :loop="false" :interval="4000" indicator-position="none" type="card" height="420px">
              <el-carousel-item v-for="item in swipperList" :ref="'index'+item.index" :class="currentPage+1== item.index? 'next' : ''" :key="item">
                <img class="swipper_img" :src="('https://sys.huwei.xin:7443/huwei-website/swipper/' + item.img)" alt="">
              </el-carousel-item>
            </el-carousel>
            <div class="button_nav">
              <img class="left_icon" v-if="currentPage == 1" src="https://sys.huwei.xin:7443/huwei-website/swipper/left_icon.png">
              <img class="left_icon2" v-if="currentPage > 1" @click="_last()" src="https://sys.huwei.xin:7443/huwei-website/swipper/right_icon.png">
              <span class="page_text">
                <span class="current_page">{{ '0'+currentPage }}</span>
                <span class="next_page"> / 05</span>
              </span>
              <img class="right_icon" v-if="currentPage < 5" @click="_next()" src="https://sys.huwei.xin:7443/huwei-website/swipper/right_icon.png">
              <img class="right_icon2" v-if="currentPage >= 5" src="https://sys.huwei.xin:7443/huwei-website/swipper/left_icon.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 绿能专家 -->
    <div class="green_energy_expert_nav">
      <div class="app_explain">
        <div class="app_explain_bg">GREEN ENERGY EXPERT</div>
        <div class="name">绿能专家</div>
        <div class="slogan">基于能源物联网的酒店绿能管理专家</div>
      </div>
      <div class="menu">
        <div class="item" v-for="item in menuList" :key="item.icon">
          <img :src="('https://sys.huwei.xin:7443/huwei-website/icon/' + item.icon)" alt="">
          <div class="label">{{ item.label }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <div class="public_benefit_column_nav">
      <div class="content_nav">
        <div class="description_text">
          <div>
            <div class="title">硬件设备</div>
            <div class="en_title">Hardware Equipment</div>
          </div>
        </div>
        <div class="menu">
          <div class="item_box" v-for="(item, index) in equipmentList" :key="item.index" >
            <div class="item">
              <div class="index_text">{{ item.index }}</div>
              <div class="label_box">
                <div class="label">{{ item.label }}</div>
                <div class="en_label">{{ item.en_label }}</div>
              </div>
            </div>
            <div class="img_box" :style="index<2?'bottom: -7px;': ''">
              <img :src="('https://sys.huwei.xin:7443/huwei-website/equipment_img/' + item.img)" :alt="item.label">
              <!-- <img class="img_shadow" :src="('https://sys.huwei.xin:7443/huwei-website/equipment_img/' + item.img)" > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="characteristic_description_nav">
      <div class="menu" v-for="item in characteristicList" :key="item.description">
        <div class="label">{{ item.label }}</div>
        <div class="description">{{ item.description }}</div>
      </div>
    </div>
    <div class="certificate_nav">
      <div class="certificate_title">
        <div class="title_bg">
          PATENT CERTIFICATION
        </div>
        <div class="title">
          <div class="title_text">专利证书</div>
          <div class="slogan">为了环保我们需要更加努力</div>
        </div>
      </div>
      <div class="certificate_content">
        <div class="certificate_menu">
          <div class="label">免改线安装简化施工</div>
          <div class="label">建筑能耗数据物联</div>
        </div>
        <div>
          <img src="https://sys.huwei.xin:7443/huwei-website/certificate_img.png" alt="">
        </div>
        <div class="certificate_menu">
          <div class="label">能耗实时监测分析</div>
          <div class="label">用电精细化计算</div>
        </div>
      </div>
    </div>
    <div class="cooperation_hotel_nav">
      <div class="app_explain">
        <div class="app_explain_bg">SOME PARTNER HOTELS</div>
        <div class="name">部分合作酒店</div>
        <div class="slogan">基于能源物联网的酒店绿能管理专家</div>
      </div>
      <div class="cooperation_content">
        <div class="cooperation_box">
          <div class="button">
            <el-icon class="icon"><ArrowLeftBold /></el-icon>
            <el-icon class="icon"><ArrowRightBold /></el-icon>
            <!-- <img src="" alt="上一页">
            <img src="" alt="下一页"> -->
          </div>
          <div class="menu">
            <div class="item_box" v-for="item in hotelList" :key="item.name">
              <img class="img" :src="('https://sys.huwei.xin:7443/huwei-website/'+item.img)" :alt="item.name">
              <div class="hotel_name">{{ item.name }}</div>
              <div class="address">{{ item.address }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance   } from "vue";
import { ArrowLeftBold, ArrowRightBold } from '@element-plus/icons'
export default {
  name: "Home",
  components: {
    ArrowLeftBold, ArrowRightBold
  },
  setup() {
    const state = computed(() => useStore().state.client);
    const swipperList = ref([
      {
        index: 1,
        img: '01.jpg'
      },
      {
        index: 2,
        img: '02.jpg'
      },
      {
        index: 3,
        img: '03.jpg'
      },
      {
        index: 4,
        img: '04.jpg'
      },
      {
        index: 5,
        img: '05.jpg'
      },
    ])
    const menuList = ref([
      {
        icon: 'data_icon.png',
        label: '数据物联',
        description: '建立电量采集分析系统，用电信息上传至云服务中心，并建立相应接口，实现对用电信息的连续监测。'
      },
      {
        icon: 'cloud_service_icon.png',
        label: '云端服务',
        description: '基于互联网相关服务的增加，使用和交互模式，关联线上线下，安全稳定。'
      },
      {
        icon: 'analysis_icon.png',
        label: '高级分析',
        description: '实现客户用电行为分析、酒店飞单研判、单会员优惠幅度建议、酒店内部线路改造建议等高级分析。'
      },
      {
        icon: 'calculate_icon.png',
        label: '精细计算',
        description: '在配电间、电梯间、设备间等关键供电部位安装电能计量装置，实现客房、电梯、热水设备、公共区域用电精细化计量。'
      },
      {
        icon: 'energy_icon.png',
        label: '能耗分析',
        description: '实现用电实时监测、用电负荷分析、用电日月年分析，以及用电过程中的异常报警、安全隐患告警。'
      },
      {
        icon: 'electric_icon.png',
        label: '电量预测',
        description: '建立一套科学完善的电能预测、计划和评估体系，为采购电量预判、用量总量监测等提供数据基础。'
      },
    ])
    const equipmentList = ref([
      {
        index: '01',
        img: 'Collector_terminal.png',
        label: '采集器终端',
        en_label: 'Collector terminal'
      },
      {
        index: '02',
        img: 'Data_management_terminal.png',
        label: '数据管理终端',
        en_label: 'Data management terminal'
      },
      {
        index: '03',
        img: 'Smoke_detector.png',
        label: '烟雾报警器',
        en_label: 'Smoke detector'
      },
      {
        index: '04',
        img: 'Closed_transformerl.png',
        label: '闭口互感器',
        en_label: 'Closed transformerl'
      },
      {
        index: '05',
        img: 'Open_transformer.png',
        label: '开口互感器',
        en_label: 'Open transformer'
      },
      {
        index: '06',
        img: 'Temperature_sensor.png',
        label: '温度传感器',
        en_label: 'Temperature sensor'
      }
    ])
    const characteristicList = ref([
      {
        label: '传感器重用',
        description: '采用共用电压互感器、独立电流互感器的方式，通过软件实现电压与电流测量数据的匹配，减少装置数量，降低成本。'
      },
      {
        label: '免改线安装',
        description: '间接式接入方法与自身设备相结合，避免了大量电力线路的投入，减少辅材投入，加快施工周期，缩短停电时间。'
      },
      {
        label: '降低通讯成本',
        description: '能耗监测装置之间通过加密Lora网络实现自组网，其中某一个位置连接酒店的宽带网络，即可实现数据上传至云服务中心，无需增加任何网络建设成本'
      },
      {
        label: '数据存储备份',
        description: '能耗采集装置和数据集中器装配大容量存储芯片，在通信故障情况下可保存数据15天不丢失，系统或网络恢复后自动将遗漏数据补报'
      },
    ])
    const hotelList = ref([
      {
        name: '杭州龙禧福朋喜来登酒店',
        img: 'xilaideng_hotel_img.png',
        address: '滨江地区 | 东信大道868号'
      },
      {
        name: '杭州海外海皇冠大酒店',
        img: 'haiwaihai_hotel_img.png',
        address: '武林广场 | 上塘路333号'
      },
      {
        name: '合肥两淮豪生大酒店',
        img: 'haosheng_hotel_img.png',
        address: '高新技术开发区 | 科学大道6号'
      },
    ])
    const currentPage = ref(1)
    const dom = getCurrentInstance()
    const change = (e) => {
      currentPage.value = e+1
    }
    const _last = () => {
      dom.refs.carusel.prev()
    }
    const _next = () => {
      dom.refs.carusel.next()
    }
    return {
      state,
      swipperList,
      change,
      currentPage,
      _last,
      _next,
      menuList,
      equipmentList,
      characteristicList,
      hotelList
    };
  },
};
</script>

<style lang="scss" scoped>
.home_box {
  width: 100%;
  // height: 982px;
  // background: linear-gradient(243deg, #003174 0%, #00224E 100%);
  .home_bg {
    background: url('https://sys.huwei.xin:7443/huwei-website/home_bg.png') no-repeat 100% 100%;
    width: 100%;
    height: 980px;
    .bg_content {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 280px 200px;
      box-sizing: border-box;
      height: 980px;
      .head_title {
        font-size: 60px;
        font-family: AlibabaPuHuiTiH;
        color: #FFFFFF;
        line-height: 58px;
        -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
      .content_text {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        padding-top: 25px;
        color: #ffffffcc;
        line-height: 36px;
        -webkit-background-clip: text;
      }
      .green_code_nav {
        display: flex;
        flex-direction: column;
        padding-top: 295px;
        .img_code {
          width: 96px;
          height: 96px;
        }
        .slogan_text {
          padding-top: 16px;
          font-size: 14px;
          color: #ffffff52;
          line-height: 20px;
        }
      }

    }
  }
  .green_energy_nav {
    .green_energy_box{
      height: 404px;
      padding: 160px 0 62px;
      box-sizing: border-box;
      .description_box {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .description_text {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        width: 1158px;
        font-weight: 500;
        color: #474747;
        line-height: 22px;
        position: relative;
        line-height: 25px;
        padding-top: 20px;
      }
      .app_explain {
        width: 100%;
        background: #ffffff;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
        .app_explain_bg {
          position: absolute;
          font-size: 150px;
          font-weight: bold;
          color: #FBFBFB;
          line-height: 120px;
        }
        .name {
          font-size: 34px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #000013;
          line-height: 42px;
          position: relative;
        }
        .slogan, .description_text {
          font-size: 18px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          width: 1158px;
          font-weight: 500;
          color: #474747;
          line-height: 22px;
          position: relative;
        }
        .slogan {
          padding: 8px 0 18px;
        }
      }
    }
    .public_benefit_column {
      background: url(https://sys.huwei.xin:7443/huwei-website/public_benefit_bg.png) no-repeat 100% 100%;
      height: 694px;
      display: flex;
      .content_nav {
        flex: 1;
        padding: 122px 0 0 353px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 30% auto;
        .description_text {
          .title {
            border-left: 4px solid #1667FF;
            color: #000013;
            font-size: 24px;
            font-family: PingFang-SC-Heavy, PingFang-SC;
            font-weight: 800;
            margin-top: 32px;
            padding-left: 12px;
            line-height: 30px;
          }
          .en_title {
            font-size: 18px;
            font-family: SFCompactText-Semibold, SFCompactText;
            font-weight: 600;
            color: #474747;
            padding-top: 8px;
            padding-left: 16px;
            line-height: 22px;
          }
          .public_benefit_content {
            padding: 100px 0 0 16px;
            .address {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #0F0F0F;
              line-height: 20px;
            }
            .coord {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #5E6070;
              line-height: 20px;
              padding-top: 8px;
            }
            .address_introduce {
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              width: 426px;
              color: #5E6070;
              line-height: 20px;
              padding-top: 16px;
            }

          }
        }
        .swipper_box {
          display: grid;
          grid-template-rows: 420px auto;
          .el-carousel--horizontal {
            overflow: hidden;
          }
          .button_nav {
            position: relative;
            // top: -60px;
            .page_text {
              font-size: 18px;
              position: absolute;
              cursor: pointer;
              width: 25px;
              left: 291px;
              top: 20px;
              white-space: nowrap;
            }
            .current_page {
              font-size: 24px;
              color: #5E6070;
            }
            .next_page {
              color: #D8D8D8;
            }
            .left_icon {
              width: 40px;
              height: 40px;
              position: absolute;
              cursor: pointer;
              left: 216px;
              top: 16px;
            }
            .left_icon2 {
              width: 40px;
              height: 40px;
              position: absolute;
              cursor: pointer;
              left: 216px;
              top: 16px;
              transform: rotateY(180deg);
            }
            .right_icon {
              width: 40px;
              height: 40px;
              position: absolute;
              cursor: pointer;
              left: 384px;
              top: 16px;
            }
          }
          .right_icon2 {
            width: 40px;
            height: 40px;
            position: absolute;
            cursor: pointer;
            left: 384px;
            top: 16px;
            transform: rotateY(180deg);
          }
          .next {
            transform: translateX(760px) scale(1) !important;
          }
          .is-in-stage {
            transform: translateX(960px) scale(1);
          }
          .is-active {
            width: 640px;
            transform: translateX(0px) scale(1) !important;
          }
          .swipper_img {
            width: 640px;
            height: 420px;
            border-radius: 10px;
          }
        }
      }
    }

  }
  .green_energy_expert_nav {
    width: 100%;
    background: #ffffff;
    text-align: center;
    display: flex;
    padding: 184px 353px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .app_explain {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .app_explain_bg {
        position: absolute;
        font-size: 150px;
        font-weight: bold;
        color: #FBFBFB;
        line-height: 120px;
      }
      .name {
        font-size: 34px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #000013;
        line-height: 42px;
        position: relative;
      }
      .slogan, .description_text {
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        width: 1158px;
        font-weight: 500;
        color: #474747;
        line-height: 22px;
        position: relative;
      }
      .slogan {
        padding: 8px 0 18px;
      }
    }
    .menu {
      display: grid;
      grid-template-columns: repeat(3,1fr);
      .item {
        width: 390px;
        height: 247px;
        margin: 0 22px;
        padding: 0 40px 40px;
        box-sizing: border-box;
        text-align: left;
        img {
          width: 64px;
          height: 64px;
        }
        .label {
          font-size: 18px;
          font-weight: 600;
          color: #02031F;
          line-height: 25px;
        }
        .description {
          font-size: 14px;
          font-weight: 400;
          color: #656973;
          line-height: 18px;
          padding-top: 8px;
        }
      }
    }
  }
  
  .public_benefit_column_nav {
    background: url(https://sys.huwei.xin:7443/huwei-website/hardware_equipment_bg.png) no-repeat 100% 100%;
    height: 910px;
    .content_nav {
      padding: 40px 353px 0;
      box-sizing: border-box;
      .description_text {
        .title {
          border-left: 4px solid #1667FF;
          color: #000013;
          font-size: 24px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          margin-top: 32px;
          padding-left: 12px;
          line-height: 30px;
        }
        .en_title {
          font-size: 18px;
          font-family: SFCompactText-Semibold, SFCompactText;
          font-weight: 600;
          color: #474747;
          padding-top: 8px;
          padding-left: 16px;
          line-height: 22px;
        }
      }
      .menu {
        display: grid;
        padding: 41px 16px;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 21px 30px;
        .item_box {
          display: flex;
          width: 358px;
          height: 313px;
          background: #FFFFFF;
          border-radius: 4px;
          position: relative;
          overflow: hidden;
          .item {
            width: 115px;
            height: 120px;
            position: relative;
            margin: 30px 0 0 30px;
            .index_text {
              position: absolute;
              color: #e9e9e999;
              font-size: 100px;
              font-weight: bold;
              left: 0;
              top: 0;
            }
            .label_box {
              position: absolute;
              bottom: 10px;
              .label {
                font-size: 18px;
                color: #3D3F55;
                line-height: 23px;
              }
              .en_label {
                font-size: 12px;
                color: #3D3F55;
                line-height: 17px;
                white-space: nowrap;
              }
            }
          }
          .img_box {
            position: absolute;
            bottom: 0;
            right: 0px;
            img {
              width: 186px;
              height: 193px;
            }
            .img_shadow {
              opacity: 0.05;
              position: absolute;
              bottom: 0;
              right: 20px;
              transform: scale(1.5) translate(40px, -30px);
            }
          }
        }
      }
    }
  }
  .characteristic_description_nav {
    background: #ffffff;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding: 86px 353px;
    box-sizing: border-box;
    .menu {
      width: 551px;
      padding-bottom: 40px;
      box-sizing: border-box;
      text-align: left;
      .label {
        font-size: 18px;
        font-weight: 600;
        color: #02031F;
        line-height: 25px;
      }
      .description {
        font-size: 14px;
        font-weight: 400;
        color: #656973;
        line-height: 18px;
        padding-top: 8px;
      }
    }
  }
  .certificate_nav {
    background: url(https://sys.huwei.xin:7443/huwei-website/certificate_bg.png) no-repeat 100% 100%;
    height: 762px;
    padding: 80px 141px 95px;
    box-sizing: border-box;
    .certificate_title {
      .title_bg {
        position: absolute;
        font-size: 137px;
        font-weight: bold;
        color: #fbfbfb8a;
        line-height: 120px;
      }
      .title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
        position: relative;
        color: #000013;
        .title_text {
          font-size: 34px;
          font-weight: 800;
          line-height: 36px;
        }
        .slogan {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          padding-top: 10px;
        }
      }
    }
    .certificate_content {
      display: flex;
      justify-content: center;
      padding-top: 80px;
      .certificate_menu {
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        margin-top: 100px;
        .label {
          width: 254px;
          height: 49px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dashed #555555;
          border-radius: 25px;
          font-size: 22px;
          font-weight: 400;
          color: #545454;
          line-height: 30px;
          margin-top: 80px;
        }
      }
    }
  }
  .cooperation_hotel_nav {
    .app_explain {
      width: 100%;
      background: #ffffff;
      text-align: center;
      display: flex;
      padding: 150px 353px 90px;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .app_explain_bg {
        position: absolute;
        font-size: 150px;
        font-weight: bold;
        color: #FBFBFB;
        line-height: 120px;
      }
      .name {
        font-size: 34px;
        font-family: PingFang-SC-Heavy, PingFang-SC;
        font-weight: 800;
        color: #000013;
        line-height: 42px;
        position: relative;
      }
      .slogan {
        font-size: 18px;
        width: 1158px;
        font-weight: 500;
        color: #474747;
        line-height: 22px;
        padding: 8px 0 18px;
        position: relative;
      }
    }
    .cooperation_content {
      background: url(https://sys.huwei.xin:7443/huwei-website/cooperation_hotel_bg.png) no-repeat 100% 100%;
      height: 767px;
      display: flex;
      .cooperation_box {
        flex: 1;
        display: flex;
        padding: 100px 210px;
        flex-direction: column;
        grid-gap: 50px;
        .button {
          display: inline-flex;
          justify-content: end;
          grid-gap: 40px;
          .icon {
            width: 57px;
            height: 57px;
            cursor: pointer;
            box-sizing: border-box;
            padding: 10px;
            color: #ffffff;
            background: #ffffff20;
            border-radius: 50%;
            font-size: 35px;
          }
        }
        .menu {
          display: flex;
          grid-gap: 35px;
          .item_box {
            .img {
              width: 474px;
              height: 272px;
            }
            .hotel_name {
              font-size: 28px;
              color: #FFFFFF;
              line-height: 34px;
            }
            .address {
              font-size: 20px;
              color: #FFFFFFb8;
              line-height: 24px;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
